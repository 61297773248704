(function(){
'use strict';
// this file executes after polyfills, so we can add promise.
// load optional features; expose promise to sync angularJS bootstrap.

function addScript( src ) {

    return new Promise( function(resolve, reject){
                var el = document.createElement("script");
                el.src = src;
                console.log('Adding bundle ' + src);
                el.onload = function(evt){
                    resolve( true );
                }
                document.getElementsByTagName("head")[0].appendChild(el);
            });
}
function addCss( href ){
    var link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    document.getElementsByTagName("head")[0].appendChild(link);
}
KOHA.toLoad.forEach(function(resource) {
    resource.loaded = addScript( resource.script );
    if( resource.css && resource.css.length ){
        resource.css.forEach(function(css){ addCss(css) });
    }
});

})();
